import { template as template_5a4aefdeb7da405484b65388f7eff2b5 } from "@ember/template-compiler";
const SidebarSectionMessage = template_5a4aefdeb7da405484b65388f7eff2b5(`
  <div class="sidebar-section-message-wrapper sidebar-row">
    <div class="sidebar-section-message">
      {{yield}}
    </div>
  </div>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default SidebarSectionMessage;
