import { template as template_52e0f02efb4b4dc8953336a28185c5cb } from "@ember/template-compiler";
const FKControlMenuContainer = template_52e0f02efb4b4dc8953336a28185c5cb(`
  <li class="form-kit__control-menu-container">
    {{yield}}
  </li>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKControlMenuContainer;
