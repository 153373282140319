import { template as template_e0d5707be73848f7bbf06b502127fd62 } from "@ember/template-compiler";
const FKLabel = template_e0d5707be73848f7bbf06b502127fd62(`
  <label for={{@fieldId}} ...attributes>
    {{yield}}
  </label>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKLabel;
